import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { SafeHtmlParser } from "./safe-html-parser";
import uuid4 from "uuid4";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import { IoIosArrowDown } from "react-icons/io";

const LandingGetInTouch = ({
  heading,
  subHeading,
  items,
  images,
  map,
  drivingDirections,
}) => {
  return (
    <section className={`my-5 my-md-6 my-xl-8`}>
      <Container>
        <Row className="mt-5 justify-content-between">
          <Col md={12} xl={6} className="pe-xl-3">
            <Row>
              <Col md={9} xl={12}>
                <div className="iframe-container">
                  <iframe
                    src={map}
                    frameBorder="0"
                    aria-hidden="false"
                    title="google-map"
                    loading="lazy"
                  ></iframe>
                </div>
              </Col>
              <Col md={3} xl={12}>
                <div className="d-flex flex-column-reverse flex-xl-column">
                  {images && images.length > 0 && (
                    <div class="mt-4 mt-md-0 mt-xl-4">
                      <div class="d-flex flex-wrap justify-content-between justify-content-md-start pt-2">
                        {images.map((item) => (
                          <a
                            href={item?.link?.url}
                            target={item?.link?.target ?? "_blank"}
                            title="Amberth"
                            className="mb-3"
                          >
                            <GatsbyImage
                              image={
                                item.image?.localFile?.childImageSharp
                                  ?.gatsbyImageData
                              }
                              alt={item.image?.altText}
                              objectPosition="top"
                              style={{ width: "64px", height: "64px" }}
                              className="me-3 me-md-4"
                            />
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                  {items && items.length > 0 && (
                    <div className="d-flex flex-column flex-md-row flex-wrap mt-4 mt-md-0 mt-xl-4">
                      {items.map((item) => (
                        <div className="pb-3 pe-5" key={uuid4()}>
                          <span className="mb-2 d-block text-lowercase h3 contact-title daxPro-regular">
                            {item.heading}
                          </span>
                          {item.link && item.link.url ? (
                            <a
                              href={item.link.url}
                              className="text-decoration-none text-black"
                            >
                              <span className="fs-6">{item.text}</span>
                            </a>
                          ) : (
                            <div className="text-black">
                              {item.text && (
                                <SafeHtmlParser htmlContent={item.text} />
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={12} xl={6} className="mt-4 mt-xl-0 ps-xl-3">
            <h3 className={`mt-0 mb-2 pb-1 intro-heading`}>{heading}</h3>
            {subHeading && (
              <p className="content-grey text-small">
                <SafeHtmlParser htmlContent={subHeading} />
              </p>
            )}
            {drivingDirections && (
              <div className="mt-4 pt-2">
                {drivingDirections.map((item, index) => (
                  <Accordion
                    className="mb-4"
                    style={{
                      border: "1px solid rgba(154, 85, 31, 0.10)",
                      cursor: "pointer",
                    }}
                  >
                    <Card className="border-0">
                      <Card.Header className="border-0 bg-white p-0 m-0">
                        <ContextAwareToggle eventKey="0" className=" border-0">
                          <p className="pb-0 fs-6 mb-0 daxPro-regular h5">
                            {item?.title}
                          </p>
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body className="pt-2 direction-content">
                          <p className="text-start mb-0">
                            <SafeHtmlParser htmlContent={item?.description} />
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);
  const [turnArrow, setTurnArrow] = useState(false);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    callback && callback(eventKey);
    setTurnArrow(!turnArrow);
  });

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className="border-0"
      style={{
        color: isCurrentEventKey ? "white" : "black",
        backgroundColor: isCurrentEventKey ? "black" : "white",
      }}
      onClick={decoratedOnClick}
    >
      <Container className="p-0" fluid={true}>
        <Row className="align-items-center mx-0">
          <Col
            className="d-flex justify-content-start align-items-center ps-0"
            xs={11}
          >
            {children}
          </Col>
          <Col xs={1} className="d-flex px-0  justify-content-end">
            <IoIosArrowDown
              className={`position-relative fs-4 text-orange cheveron  ${
                turnArrow ? "cheveron-down" : ""
              }`}
            />
          </Col>
        </Row>
      </Container>
    </Card.Header>
  );
}

export default LandingGetInTouch;
