import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import uuid4 from "uuid4";
import { SafeHtmlParser } from "./safe-html-parser";
import LandingContactForm from "./landing-contactFrom";
import ContactForm from "./contactForm";
import QrPageForm from "./qr-page-form";
import LandingContactFormNew from "./landing-contact-form-new";

const Hero = ({
	heading,
	subheading,
	backgroundImage,
	content,
	testimonials,
	items,
	isDefaultForm,
	formId,
	page,
	redirect,
}) => {
	const overlayColor = "rgba(0, 0, 0, 0.4)";
	const overlayStyle = {
		background: `linear-gradient(${overlayColor} 0%, ${overlayColor} 100%)`,
		backgroundBlendMode: "multiply",
		zIndex: 1,
	};

	return (
		<div style={{ minHeight: "700px" }} className="hero-home h-100">
			<BgImage
				className="w-100 h-100 d-flex align-items-start justify-content-center banner-landing"
				image={getImage(backgroundImage)}
				style={{ minHeight: "700px" }}
				key={uuid4()}
			>
				<div
					className="position-absolute start-0 top-0 h-100 w-100"
					style={overlayStyle}
				></div>
				<Container className="py-5 py-md-6 h-100" style={{ zIndex: 2 }}>
					<Row className="justify-content-between align-items-start h-100">
						<Col xs={12} xl={6} className="text-start">
							<div className="w-100 pe-xl-5 position-relative text-center text-xl-start">
								{subheading && (
									<h1 className="text-white small-text mb-2 h4">
										{subheading}
									</h1>
								)}
								<span className="mb-0 h1 text-white fs-1">{heading}</span>
								{content && (
									<div className="content-white mt-3">
										<SafeHtmlParser htmlContent={content} />
									</div>
								)}

								{items && items.length > 0 && (
									<div class="d-flex justify-content-between justify-content-sm-center justify-content-xl-start mt-5">
										{items.map((item) => (
											<a
												href={item?.link?.url}
												target={item?.link?.target ?? "_blank"}
												title="Back to Homepage"
											>
												<GatsbyImage
													image={
														item.image?.localFile?.childImageSharp
															?.gatsbyImageData
													}
													alt={item.image?.altText}
													objectPosition="top"
													className="me-3 me-md-4 banner-images"
												/>
											</a>
										))}
									</div>
								)}

								<div className="d-flex text-start mt-5 ms-md-3 align-items-center mt-3 justify-content-center justify-content-md-start">
									<StaticImage
										quality="100"
										src="../images/5star.svg"
										alt="google"
										placeholder="blurred"
										width={100}
										formats={["auto", "webp"]}
										className="me-2"
									/>
									<p className="text-white fs-6 py-0 my-0 me-2">
										<span className="fw-bold fs-6 text-white daxpro-semibold">
											4.8
										</span>
									</p>

									<p className="text-white fs-6 py-0 my-0 daxpro-semibold">
										<span className="me-1">|</span> 56 reviews on Houzz
									</p>
								</div>
								{testimonials && testimonials.length > 0 && (
									<>
										{testimonials.splice(0, 1).map((item, i) => (
											<div
												className="mt-4 ms-md-3 text-start mx-4 mx-md-0"
												key={i}
											>
												{item.content && (
													<div className="d-flex align-items-start">
														<StaticImage
															quality="100"
															src="../images/quote.png"
															alt="google"
															placeholder="blurred"
															width={32}
															height={32}
															formats={["auto", "webp"]}
															className="me-2 mt-1"
															style={{ flexBasis: "40px" }}
														/>
														<div className="content-white text-small daxpro-semibold">
															<SafeHtmlParser htmlContent={item.content} />
														</div>
													</div>
												)}

												<p className="text-white fs-6 daxpro-semibold">
													{item.title}
												</p>
											</div>
										))}
									</>
								)}
							</div>
						</Col>

						<Col xs={12} md={9} xl={5} className="text-center">
							<div
								className="w-100 py-4 px-3 px-sm-5 bg-white mt-5 my-4 my-xl-2"
								style={{ minHeight: "600px", scrollMarginTop: "80px" }}
								id="form"
							>
								<LandingContactFormNew page={page} redirect={redirect} />
							</div>
						</Col>
					</Row>
				</Container>
			</BgImage>
		</div>
	);
};

export default Hero;
