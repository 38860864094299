import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { SafeHtmlParser } from "./safe-html-parser";

const CtaSection = ({ heading, content, button, backgroundImage }) => {
  const overlayStyle = {
    background: `rgba(0, 0, 0, 0.5)`,
    zIndex: 1,
  };
  
  const image = backgroundImage ? getImage(backgroundImage) : null;

  const ctaContent = (
    <Container style={{ zIndex: 2 }} className="position-relative py-5 py-md-8">
      <Row className={`align-items-cente justify-content-center`}>
        <Col xl={10} className={`mb-4 mb-xl-0 text-white text-center`}>
          <h2 className="my-0 pb-3 pb-md-6 white">{heading}</h2>
          {content && (
            <div className="content-white">
              <SafeHtmlParser htmlContent={content} />
            </div>
          )}
        </Col>
      </Row>

      {button && button.url && (
        <Button
          as={Link}
          href={button.url}
          size="lg"
          className="mt-4 mt-md-5 mx-auto d-flex px-4 py-3 text-uppercase daxPro-bold w-fit rounded-0"
          target={button.target}
          variant="transparent"
        >
          {button.title}
        </Button>
      )}
    </Container>
  );
  return (
    <BgImage image={image}>
      <div
        className="position-absolute start-0 top-0 h-100 w-100"
        style={overlayStyle}
      ></div>
      {ctaContent}
    </BgImage>
  );
};

export default CtaSection;
