import { Link } from "gatsby";
import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Sticky from "react-stickynode";
import DesktopLogo from "../images/amberth-crop.svg";
import DesktopLogoWhite from "../images/white-logo.svg";

const Navigation = ({ isThankYouPage = false }) => {
  return (
    <>
      {isThankYouPage ? (
        <Navbar
		expand="lg"
		className={"shadow-none position-sticky default-nav pt-5"}
		style={{ zIndex: 1111 }}
	  >
		<Container style={{ zIndex: 2 }} className="position-relative">
		  <Navbar.Brand
			as={Link}
			to="/"
			className="p-0 mx-auto mx-lg-0 d-flex justify-content-center w-100"
		  >
			<img
			  src={DesktopLogoWhite}
			  alt="Amberth"
			  style={{ maxWidth: "221px" }}
			/>
		  </Navbar.Brand>
		</Container>
	  </Navbar>
      ) : (
        <Sticky innerZ={1030}>
          <div>
            <Navbar
              expand="lg"
              className={"bg-light-grey position-sticky default-nav"}
            >
              <Container style={{ zIndex: 2 }} className="position-relative">
                <Navbar.Brand
                  as={Link}
                  to="/"
                  className="p-0 mx-auto mx-lg-0 d-flex justify-content-center justify-content-md-start w-100"
                >
                  <img
                    src={DesktopLogo}
                    alt="Amberth"
                    style={{ maxWidth: "221px" }}
                  />
                </Navbar.Brand>
              </Container>
            </Navbar>
          </div>
        </Sticky>
      )}
    </>
  );
};

export default Navigation;
