import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import uuid4 from "uuid4";
import { SafeHtmlParser } from "./safe-html-parser";
import LandingContactForm from "./landing-contactFrom";
import ContactForm from "./contactForm";
import QrPageForm from "./qr-page-form";
import Carousel from "react-elastic-carousel";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";
import LandingContactFormNew from "./landing-contact-form-new";

const Hero = ({
	heading,
	subheading,
	mobileGallery,
	content,
	testimonials,
	items,
	isDefaultForm,
	formId,
	button,
	page,
	redirect,
}) => {
	const carouselRef = useRef(null);

	return (
		<div className="hero-home-mobile h-100">
			<Container className="my-5 my-md-0 py-md-6 px-3 px-xl-4 h-100">
				{mobileGallery && mobileGallery?.length > 0 && (
					<Carousel
						ref={carouselRef}
						itemsToShow={1}
						className="h-100"
						showArrows={false}
						pagination={true}
						enableAutoPlay={true}
						autoPlaySpeed={5000}
					>
						{mobileGallery.map((sliderItem, index) => (
							<BgImage
								className="w-100 h-100 d-flex align-items-center justify-content-center"
								image={getImage(
									sliderItem?.localFile?.childImageSharp?.gatsbyImageData,
									{ loading: index === 0 ? "eager" : "lazy" } // Eager load first image, lazy load others
								)}
								style={{ minHeight: "335px" }}
								key={uuid4()}
							/>
						))}
					</Carousel>
				)}
				<Row className="justify-content-between align-items-start h-100">
					<Col xs={12} xl={6} className="text-start">
						<div className="w-100 px-xl-5 position-relative text-center text-xl-start">
							{subheading && (
								<h1 className="small-text mb-2 h4">{subheading}</h1>
							)}
							<h2 className="mb-0 h1 fs-1">{heading}</h2>
							{content && (
								<div className="mt-3 fs-4 content-grey text-large-mobile">
									<SafeHtmlParser htmlContent={content} />
								</div>
							)}
							<Button
								className="px-4 py-3 mt-3 mb-0 w-fit daxPro-semibold d-md-none"
								as={Link}
								to={button?.url}
								variant="secondary"
								target={button?.target ?? "_blank"}
							>
								{button?.title ?? "Contact Us"}
							</Button>

							{items && items.length > 0 && (
								<div class="d-flex justify-content-between justify-content-sm-center justify-content-xl-start mt-5">
									{items.map((item) => (
										<a
											href={item?.link?.url}
											target={item?.link?.target ?? "_blank"}
											title="Back to Homepage"
										>
											<GatsbyImage
												image={
													item.mobileImage?.localFile?.childImageSharp
														?.gatsbyImageData
												}
												alt={item.mobileImage?.altText}
												objectPosition="top"
												className="me-3 me-md-4 banner-images"
											/>
										</a>
									))}
								</div>
							)}

							<div className="border mt-6 mb-4 p-4">
								<div className="d-flex text-start align-items-center justify-content-start">
									<StaticImage
										quality="100"
										src="../images/5star.svg"
										alt="google"
										placeholder="blurred"
										width={100}
										formats={["auto", "webp"]}
										className="me-2"
									/>
									<p className="fs-6 py-0 my-0 me-2">
										<span className="fw-bold fs-6 daxpro-semibold">4.8</span>
									</p>

									<p className="fs-6 py-0 my-0 daxpro-semibold">
										<span className="me-1">|</span> 56 reviews on Houzz
									</p>
								</div>
								{testimonials && testimonials.length > 0 && (
									<>
										{testimonials.splice(0, 1).map((item, i) => (
											<div className="text-start mt-4" key={i}>
												{item.content && (
													<div className="d-flex align-items-start">
														<StaticImage
															quality="100"
															src="../images/quote-orange.svg"
															alt="google"
															placeholder="blurred"
															width={40}
															height={40}
															formats={["auto", "webp"]}
															className="me-2 mt-1"
															style={{ flexBasis: "80px" }}
														/>
														<div className="daxpro-semiBole content-grey">
															<SafeHtmlParser htmlContent={item.content} />
														</div>
													</div>
												)}

												<h6 className="mt-3 fs-6 fst-italic daxpro-regular text-primary">
													{item.title}
												</h6>
											</div>
										))}
									</>
								)}
							</div>
						</div>
					</Col>

					<Col xs={12} md={9} xl={5} className="text-center mx-auto">
						<div
							className="w-100 py-4 px-3 px-sm-5 bg-white border"
							style={{ minHeight: "600px", scrollMarginTop: "-250px" }}
							id="form"
						>
							<LandingContactFormNew page={page} redirect={redirect} />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Hero;
