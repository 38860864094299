import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Layout from "../components/layout";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import HeroSection from "../components/hero";
import SectionLayout2 from "../components/section-layout-2";
import SectionLayout3 from "../components/section-layout-3";
import SectionLayout4 from "../components/section-layout-4";
import SectionLayout5 from "../components/section-layout-5";
import SectionLayout6 from "../components/section-layout-6";
import SectionLayout7 from "../components/section-layout-7";
import SectionLayout8 from "../components/section-layout-8";
import SectionLayout9 from "../components/section-layout-9";
import SectionLayout10 from "../components/section-layout-10";
import SectionLayout12 from "../components/section-layout-12";
import SectionLayout11 from "../components/section-layout-11";
import SectionLayout13 from "../components/section-layout-13";
import uuid4 from "uuid4";
import FAQSchemaMarkup from "../components/FAQSchemaMarkup";

const PageTemplate = ({
	data: { site, pageData, allWpPost, isShowLayout = true, isHomePage = false },
}) => {
	const siteUrl = site.siteMetadata.siteUrl;

	let breadcrumb = {};
	if (isHomePage) {
		breadcrumb = {
			"@context": "http://schema.org",
			"@type": "BreadcrumbList",
			itemListElement: [
				{
					"@type": "ListItem",
					position: "1",
					name: "Home",
					item: {
						url: `${siteUrl}`,
						id: `${siteUrl}`,
					},
				},
			],
		};
	} else {
		breadcrumb = {
			"@context": "http://schema.org",
			"@type": "BreadcrumbList",
			itemListElement: [
				{
					"@type": "ListItem",
					position: "1",
					name: "Home",
					item: {
						url: `${siteUrl}`,
						id: `${siteUrl}`,
					},
				},
				{
					"@type": "ListItem",
					position: "2",
					name: pageData.title,
					item: {
						url: `${siteUrl}/${pageData.slug}`,
						id: `${siteUrl}/${pageData.slug}`,
					},
				},
			],
		};
	}

	const {
		seoFieldGroups,
		pageFieldsGroup: { pageFieldsGroup },
	} = pageData;

	// console.log(pageFieldsGroup);
	const layoutContent = (
		<>
			{pageFieldsGroup &&
				pageFieldsGroup.length > 0 &&
				pageFieldsGroup.map((sectionInfo) => (
					<>
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_BannerSection" && (
							<HeroSection
								hideBg={sectionInfo?.hideBox}
								items={sectionInfo?.items}
								key={uuid4()}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout2" && (
							<SectionLayout2
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								button={sectionInfo?.link}
								button2={sectionInfo.bottomLink}
								key={uuid4()}
								projectType={sectionInfo?.projectType}
							/>
						)}

						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout3" && (
							<SectionLayout3
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								button={sectionInfo?.link}
								items={sectionInfo?.items}
								key={uuid4()}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout4" && (
							<SectionLayout4
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								author={sectionInfo?.author}
								backgroundImage={
									sectionInfo?.backgroundImage?.localFile.childImageSharp
										.gatsbyImageData
								}
								key={uuid4()}
								button={sectionInfo?.link}
								testimonials={sectionInfo?.testimonials}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout5" && (
							<SectionLayout5
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								button={sectionInfo?.link}
								image={
									sectionInfo?.image?.localFile.childImageSharp.gatsbyImageData
								}
								imageAlt={sectionInfo?.image?.altText}
								key={uuid4()}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout6" && (
							<SectionLayout6
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								button={sectionInfo?.link}
								button2={sectionInfo.bottomLink}
								key={uuid4()}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout7" && (
							<SectionLayout7
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								button={sectionInfo?.link}
								button2={sectionInfo.link2}
								button3={sectionInfo?.link3}
								key={uuid4()}
								items={sectionInfo?.items}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout8" && (
							<SectionLayout8
								heading={sectionInfo?.heading}
								button={sectionInfo?.link}
								button2={sectionInfo.link2}
								button3={sectionInfo?.link3}
								key={uuid4()}
								items={sectionInfo?.items}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout9" && (
							<SectionLayout9
								heading={sectionInfo?.heading}
								key={uuid4()}
								items={sectionInfo?.items}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout10" && (
							<SectionLayout10
								key={uuid4()}
								heading={sectionInfo?.heading}
								listTitle={sectionInfo?.listTitle}
								link={sectionInfo?.link}
								image={
									sectionInfo?.image.localFile.childImageSharp.gatsbyImageData
								}
								items={sectionInfo?.testimonials}
								bannerTestimonial={sectionInfo?.bannerTestimonial}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout11" && (
							<SectionLayout11
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								key={uuid4()}
								items={
									sectionInfo?.blogs && sectionInfo?.blogs.length > 0
										? sectionInfo?.blogs
										: allWpPost.nodes
								}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout12" && (
							<SectionLayout12
								heading={sectionInfo?.heading}
								content={sectionInfo?.description}
								button={sectionInfo?.link}
								column1={sectionInfo?.column1}
								column2={sectionInfo?.column2}
								key={uuid4()}
							/>
						)}
						{sectionInfo.fieldGroupName ===
							"Page_Pagefieldsgroup_PageFieldsGroup_SectionLayout13" && (
							<SectionLayout13
								key={uuid4()}
								heading={sectionInfo?.heading}
								subHeading={sectionInfo?.subheading}
								button={sectionInfo?.link}
								button2={sectionInfo.link2}
								button3={sectionInfo?.link3}
								brochure={sectionInfo?.brochure.mediaItemUrl}
								iframeSrc={sectionInfo?.iframeSrc}
							/>
						)}
					</>
				))}
		</>
	);

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{isHomePage && (
				<LocalBusinessSchema
					imageUrl={seoFieldGroups?.image?.localFile?.publicURL}
				/>
			)}
			{pageData.slug === "faqs" && <FAQSchemaMarkup />}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${pageData.slug}`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@amberthdesign",
					site: "@amberthdesign",
					cardType: "summary",
				}}
			/>

			{isShowLayout ? <Layout>{layoutContent}</Layout> : layoutContent}
		</>
	);
};

export default PageTemplate;

export const pageQuery = graphql`
	query ($id: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		allWpPost(sort: { order: DESC, fields: date }) {
			nodes {
				title
				uri
				slug
				postFields {
					featureImage {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
				terms {
					nodes {
						name
					}
				}
				content
			}
		}
		pageData: wpPage(slug: { eq: $id }) {
			title
			slug
			seoFieldGroups {
				metaTitle
				metaDescription
				localBusinessSchema
				openGraphDescription
				openGraphTitle
				productSchema
				image {
					altText
					publicUrl
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
							original {
								width
								height
							}
						}
					}
				}
			}
			pageFieldsGroup {
				pageFieldsGroup {
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_BannerSection {
						fieldGroupName
						hideBox
						items {
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
							fieldGroupName
							heading
							link {
								target
								title
								url
							}
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout2 {
						description
						fieldGroupName
						bottomLink {
							target
							title
							url
						}
						heading
						link {
							target
							title
							url
						}
						projectType {
							name
							slug
							uri
							description
							projectTypeFields {
								detailPageDescription
								detailPageTitle
								fieldGroupName
								image {
									altText
									localFile {
										publicURL
										childImageSharp {
											original {
												height
												width
											}
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							projects {
								nodes {
									title
									slug
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout3 {
						description
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						items {
							description
							fieldGroupName
							heading
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout4 {
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						testimonials {
							... on WpTestimonial {
								id
								title
								content
							}
						}
						backgroundImage {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout5 {
						description
						fieldGroupName
						heading
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						link {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout6 {
						description
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout7 {
						description
						fieldGroupName
						heading
						items {
							description
							fieldGroupName
							heading
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						link {
							target
							title
							url
						}
						link2 {
							target
							title
							url
						}
						link3 {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout8 {
						fieldGroupName
						heading
						items {
							... on WpFaq {
								id
								title
								slug
								faqFields {
									faqFields {
										answer
										fieldGroupName
										question
									}
								}
							}
						}
						link {
							target
							title
							url
						}
						link2 {
							target
							title
							url
						}
						link3 {
							target
							title
							url
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout9 {
						fieldGroupName
						heading
						items {
							heading
							fieldGroupName
							text
							link {
								target
								title
								url
							}
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout10 {
						fieldGroupName
						heading
						listTitle
						link {
							target
							title
							url
						}
						image {
							altText
							localFile {
								publicURL
								childImageSharp {
									original {
										height
										width
									}
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						bannerTestimonial {
							... on WpTestimonial {
								id
								title
								content
								featuredImage {
									node {
										altText
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
						testimonials {
							... on WpTestimonial {
								id
								title
								date
								content
								featuredImage {
									node {
										altText
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								testimonialsFields {
									authorImage {
										altText
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout11 {
						description
						fieldGroupName
						blogs {
							... on WpPost {
								title
								uri
								slug
								content
								postFields {
									timeToRead
									featureImage {
										altText
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
								}
								terms {
									nodes {
										name
									}
								}
							}
						}
						heading
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout12 {
						description
						fieldGroupName
						heading
						link {
							target
							title
							url
						}
						column1 {
							description
							fieldGroupName
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						column2 {
							description
							fieldGroupName
							image {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					... on WpPage_Pagefieldsgroup_PageFieldsGroup_SectionLayout13 {
						fieldGroupName
						heading
						iframeSrc
						brochure {
							sourceUrl
							mediaItemUrl
						}
						link {
							target
							title
							url
						}
						link2 {
							target
							title
							url
						}
						link3 {
							target
							title
							url
						}
						subheading
					}
				}
			}
		}
	}
`;
