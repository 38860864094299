import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import uuid4 from "uuid4";
import TestimonialCard from "./testimonial-card";

const TestimonialList = ({ heading, items, button }) => {
  return (
    <section className={`my-8 my-md-6 my-xl-8`}>
      <Container>
        <Row className="justify-content-center">
          <Col lg={9} className={`text-center px-3 px-md-2 pb-2 pb-md-0 mb-4 mb-md-0`}>
            <h2 className="mb-0">{heading}</h2>
          </Col>
        </Row>
        {items && items.length > 0 && (
          <Row className="mt-md-5 g-md-40">
            {items.splice(0, 3).map((item) => (
              <TestimonialCard
                title={item.title}
                image={
                  item?.featuredImage?.node?.localFile?.childImageSharp
                    .gatsbyImageData
                }
                content={item.content}
                imageAlt={item.postFields?.featureImage?.altText}
                key={uuid4()}
              />
            ))}
          </Row>
        )}
        {button && button.url && (
          <Button
            className="px-4 py-3 mt-4 mt-md-5 mb-4 mb-md-0 mx-auto w-fit button-fade-1 daxPro-semibold"
            as={Link}
            to={button?.url}
            variant="secondary"
            target={button?.target ?? "_blank"}
          >
            {button?.title}
          </Button>
        )}
      </Container>
    </section>
  );
};

export default TestimonialList;
