import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      termsPdf: wpMediaItem(
        title: { eq: "Amberth-Terms-and-Conditions-2021" }
      ) {
        altText
        publicUrl
      }
    }
  `);
  return (
    <footer className="bg-dark-bg py-4 py-md-5">
      <Container>
        <Row className="pb-4 jusitfy-content-center">
          <Col
            className="mt-sm-4 mt-md-0  text-start text-white"
            
            md={{ span: 4 }}
          >
            {" "}
            <p className="mb-2 text-white">
              311 essex road london n1 2bd
            </p>
            
          </Col>
          <Col
            className="mt-sm-4 mt-md-0 text-sm-center text-white"
           
            md={{ span: 4 }}
          >
            {" "}
           
            <div className="mb-2">
              t:{" "}
              <a className="footer-link" href="tel:+442073548958">
                +44 (0)207 354 8958
              </a>
            </div>
            
          </Col>
          <Col
            className="mt-sm-4 mt-md-0  text-sm-end text-white"
            
            md={{ span: 4 }}
          >
            {" "}
          
            <div className="mb-2">
              e:{" "}
              <a className="footer-link" href="mailto:studio@amberth.co.uk">
                studio@amberth.co.uk
              </a>{" "}
            </div>
          </Col>
        </Row>

        <div className="d-flex align-items-center pt-3 flex-column flex-md-row justify-content-center ">
          <div className="d-flex gap-6 bottom-footer">
            <span className="mb-0 daxPro-regular text-white small-text">
              © {new Date().getFullYear()} Amberth
            </span>
            <Link
              className="d-block footer-link small-text"
              to="/privacy-notice"
            >
              Privacy Notice
            </Link>
            <a
              style={{ cursor: "hover" }}
              className="d-block footer-link small-text"
              href="https://amberth.rjmdigital.net/wp-content/uploads/2024/06/Amberth-Terms-and-Conditions-2021.pdf"
              target="_blank"
            >
              T&Cs
            </a>
          </div>
          <div className="mt-4 mt-md-0 text-white d-flex align-items-center small-text ms-md-6">
            <p></p>Website by{" "}
            <span className="ms-1">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.rjm.digital"
                className="footer-link daxPro-semibold"
              >
                RJM Digital
              </a>
            </span>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
